import * as React from 'react';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { isBrowser } from '@dx-ui/utilities-is-browser';

export enum SessionContextType {
  SignOut = 'SignOut',
  SessionTimeout = 'SessionTimeout',
  ShowForceSignOut = 'ShowForceSignOut',
  ShowConfirmSignOut = 'ShowConfirmSignOut',
  SignOutAndStayOnPage = 'SignOutAndStayOnPage',
  None = 'None',
}

type Message = {
  message: 'unauthorised' | 'none';
  accessToken?: string;
};

/**
 * Event bus to communicate between apollo links and react
 */
class SessionContextEmitter {
  private fns: ((message: Message) => void)[] = [];
  public subscribe(fn: (m: Message) => void) {
    this.fns.push(fn);
    return () => {
      const index = this.fns.findIndex((f) => f === fn);
      this.fns = [...this.fns.slice(0, index), ...this.fns.slice(index + 1)];
    };
  }
  public broadcast(message: Message) {
    this.fns.forEach((f) => f(message));
  }
}

export const eventEmitter = new SessionContextEmitter();

type ContextType = keyof typeof SessionContextType;

type SessionProviderProps = {
  sessionContext: SessionContextType;
  setSessionContext: (newContextType: ContextType) => void;
};

export const SessionContext = React.createContext<SessionProviderProps>({
  sessionContext: SessionContextType.None,
  setSessionContext: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});
export const SessionConsumer = SessionContext.Consumer;
export const SessionProvider: React.FC<
  React.PropsWithChildren<{ contextType: SessionContextType }>
> = ({ contextType, children }) => {
  const [sessionContextType, setSessionContextType] =
    React.useState<SessionContextType>(contextType);
  function updateSessionContext(newContextType: ContextType) {
    setSessionContextType(SessionContextType[newContextType]);
  }
  const { isAuthenticated } = useAuth();

  if (isBrowser && !isAuthenticated) {
    window.sessionStorage.clear();
  }

  React.useEffect(() => {
    const unsubscribe = eventEmitter.subscribe(({ message }) => {
      if (message === 'unauthorised') {
        setSessionContextType(SessionContextType.SessionTimeout);
      }
      if (message === 'none') {
        setSessionContextType(SessionContextType.None);
      }
    });
    return () => unsubscribe();
  }, [setSessionContextType]);

  return (
    <SessionContext.Provider
      value={{ sessionContext: sessionContextType, setSessionContext: updateSessionContext }}
    >
      {children}
    </SessionContext.Provider>
  );
};
