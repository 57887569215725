export const isBrowser = process.browser;
export const isDev: boolean = process.env.NODE_ENV !== 'production';
export const CUSTOM_URL_LABEL = 'my-event.hilton.com/';
export const CONFIRMATION_PAGE_LOADED = 'confirmationPageLoaded';
export const USERNAME_COOKIE = 'hhUser';
export const URL_DATE_FORMAT = 'yyyy-MM-dd';
export const STRING_DATE_FORMAT = 'MMMM d, yyyy';
export const LOGGED_IN_USER_TIMEOUT_SECONDS = 12 * 60; // 12 minutes
export const COUNT_DOWN_SECONDS = 3 * 60; // 3 minutes
export const IDLE_CHECK_INTERVAL = 10000; // 10 Seconds
export const COUNT_DOWN_INTERVAL = 1000; // 1 Seconds
export const EVENT_DESCRIPTION = 1000; // characters

export enum Steps {
  EventSearch = '1',
  ContactDetails = '2',
  CreateEvent = '3',
  Preview = '4',
  Confirmation = '5',
}

export enum PageStatus {
  OK = '',
  ERROR = 'error',
}

export enum ErrorType {
  None = 'none',
  FormError = 'formError',
  CreatePageError = 'createPageError',
  EventExistsError = 'eventExistsError',
  EventInfoRemoved = 'eventInfoRemoved',
  CorpUser403Error = 'corpUser403Error',
}

export type IPageError = {
  type: ErrorType;
  values?: {
    count?: number;
    [key: string]: string | number | boolean | undefined;
  };
};
