import getConfig from 'next/config';
import { metricsConfig, createMetrics } from '@dx-ui/config-metrics';
import { digitalData } from './digitalData';
import ExtendedAdobeTagManager from './ExtendedAdobeTagManager';

const { publicRuntimeConfig } = getConfig();
const config = metricsConfig({
  digitalData,
  debug: !process.env.DISABLE_METRICS_DEBUGGING && /local|test/.test(process.env.APP_ENV || ''),
  Interface: ExtendedAdobeTagManager,
  seedFile: publicRuntimeConfig.TRACKING_SEED_FILE,
  server: typeof window === 'undefined',
});

export default createMetrics(config);
