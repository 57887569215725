import { AuthClient } from '@dx-ui/framework-auth-provider';
import type { GraphQLError } from 'graphql';
import type { ApolloError } from 'apollo-server-errors';
import getConfig from 'next/config';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import type { IncomingMessage } from 'http';

export type NetworkError = Error | ApolloError;

export type TGraphQLError = GraphQLError & { code: number };

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

export const isUnauthorizedMessage = (errors: ReadonlyArray<TGraphQLError>) =>
  errors.some(
    (error) =>
      error.message === 'Unauthorized' ||
      error.message === 'Forbidden' ||
      error.code === 403 ||
      error.code === 401
  );

export const isCausedByUnauthorized = (
  graphQLErrors: ReadonlyArray<TGraphQLError> | undefined,
  networkError: NetworkError | undefined
) => {
  if (graphQLErrors) {
    return isUnauthorizedMessage(graphQLErrors);
  }
  if (networkError) {
    return (networkError as ApolloError).statusCode === 401;
  }
  return false;
};

export const graphqlErrorCode = (graphQLErrors?: ReadonlyArray<TGraphQLError>) => {
  const graphQLErrorsCode: string = graphQLErrors?.[0]?.extensions?.code || '0';
  return parseInt(graphQLErrorsCode, 10);
};

export const isCausedBy403 = (
  graphQLErrors: ReadonlyArray<TGraphQLError> | undefined,
  networkError: NetworkError | null | undefined
) =>
  (networkError && (networkError as ApolloError).statusCode === 403) ||
  graphqlErrorCode(graphQLErrors as unknown as TGraphQLError[]) === 403;

export const getAuthClient = (
  req?: IncomingMessage,
  initialAuthState?: ConstructorParameters<typeof AuthClient>[0]['restore']
): AuthClient =>
  new AuthClient({
    authEndpoint: isBrowser
      ? publicRuntimeConfig.DX_AUTH_API_CLIENT_URI
      : serverRuntimeConfig.DX_AUTH_API_SERVER_URI,
    gqlEndpoint: isBrowser
      ? publicRuntimeConfig.DX_GRAPHQL_CLIENT_URI
      : serverRuntimeConfig.DX_GRAPHQL_SERVER_URI,
    appId: publicRuntimeConfig.DX_AUTH_API_CUSTOMER_APP_ID,
    request: req,
    restore: initialAuthState,
    appName: 'my-event',
  });

export function isCMSError(graphQLErrors: ReadonlyArray<TGraphQLError> | undefined) {
  return graphQLErrors?.[0]?.code === 998;
}
