/* eslint-disable no-console */
import { useCallback, useEffect, useReducer, useRef } from 'react';

import * as React from 'react';
import type { AuthClient } from './auth-client';
import type { AuthInfo } from './auth-context';
import { AuthContext } from './auth-context';
import type { GuestQuery } from './gql/types';
import type { LoginResponse } from './types';
import { flushSync } from 'react-dom';
import { logDynatraceWarning } from '@dx-ui/framework-dynatrace';

type State = {
  loggedIn: boolean;
  guestInfo: GuestQuery['guest'] | null;
  isLoading: boolean;
  authInfo: AuthInfo | undefined;
  authClient: AuthClient;
};

type LogoutAction = {
  type: 'logout';
};

type GetGuestInfoAction = {
  type: 'GuestInfo';
  data: GuestQuery['guest'];
};

type StartFetchingGuestInfo = {
  type: 'StartFetchingGuestInfo';
};

type SetAuthInfo = {
  type: 'SetAuthInfo';
  data: AuthInfo;
};

function reducer(
  state: State,
  action: LogoutAction | GetGuestInfoAction | StartFetchingGuestInfo | SetAuthInfo
) {
  if (action.type === 'logout') {
    return {
      ...state,
      loggedIn: false,
      isLoading: false,
      guestInfo: null,
    };
  }
  if (action.type === 'StartFetchingGuestInfo') {
    return {
      ...state,
      loggedIn: true,
      isLoading: true,
      guestInfo: null,
    };
  }
  if (action.type === 'GuestInfo') {
    return {
      ...state,
      loggedIn: true,
      isLoading: false,
      guestInfo: action.data,
    };
  }
  if (action.type === 'SetAuthInfo') {
    return {
      ...state,
      authInfo: action.data,
    };
  }
  return state;
}

export function AuthProvider({
  authClient,
  children,
  onLoginAttempt,
  onLogout,
  initialState,
}: {
  authClient: AuthClient;
  children: React.ReactNode;
  onLoginAttempt?: (response: LoginResponse) => Promise<AuthInfo>;
  onLogout?: () => Promise<AuthInfo>;
  initialState?: AuthInfo;
}) {
  const [state, dispatch] = useReducer<typeof reducer>(reducer, {
    authClient,
    loggedIn: !!authClient.getIsLoggedIn(),
    isLoading: authClient.getIsLoggedIn(),
    guestInfo: null,
    authInfo: initialState,
  });

  const mounted = useRef<boolean>(false);
  const safeDispatch = useCallback(function (v: Parameters<typeof dispatch>[0]) {
    if (mounted.current) {
      dispatch(v);
    }
  }, []);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    function logoutCb() {
      safeDispatch({
        type: 'logout',
      });
      onLogout?.()
        .then((info) => safeDispatch({ type: 'SetAuthInfo', data: info }))
        .catch((error) => logDynatraceWarning('AUTH_ON_LOGOUT', error));
    }
    function loginFaliureCb() {
      safeDispatch({
        type: 'logout',
      });
    }
    function loginSuccessCb(response: LoginResponse) {
      flushSync(() =>
        safeDispatch({
          type: 'StartFetchingGuestInfo',
        })
      );

      onLoginAttempt?.(response)
        .then((info) => safeDispatch({ type: 'SetAuthInfo', data: info }))
        .catch((error) => logDynatraceWarning('AUTH_ON_LOGIN_ATTEMPT', error));
      authClient
        .getGuestInfo()
        ?.then((info) => {
          if (info) {
            safeDispatch({
              type: 'GuestInfo',
              data: info?.guest,
            });
          } else {
            safeDispatch({
              type: 'logout',
            });
          }
        })
        .catch((error) => logDynatraceWarning('AUTH_GET_GUEST_INFO', error));
    }
    authClient.on('logout', logoutCb);
    authClient.on('loginSuccess', loginSuccessCb);
    authClient.on('loginFailure', loginFaliureCb);

    authClient
      .getGuestInfo()
      ?.then((info) => {
        if (info?.guest) {
          safeDispatch({
            type: 'GuestInfo',
            data: info?.guest,
          });
        } else {
          safeDispatch({
            type: 'logout',
          });
        }
      })
      .catch((error) => logDynatraceWarning('AUTH_GET_GUEST_INFO', error));

    return () => {
      authClient.off('logout', logoutCb);
      authClient.off('loginSuccess', loginSuccessCb);
      authClient.off('loginFailure', loginFaliureCb);
    };
  }, [authClient, onLoginAttempt, onLogout, safeDispatch]);

  const value = {
    authClient,
    isAuthenticated: state.loggedIn,
    logout: () => authClient.logout(),
    login: (response: LoginResponse) => authClient.login(response),
    refreshGuestInfo: () => authClient.refreshGuestInfo(),
    guestInfo: state.guestInfo,
    authInfo: state.authInfo,
    guestId: authClient.getGuestId() || null,
    isLoading: state.isLoading,
    isCorpUser: authClient.isCorpUser(),
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}
